import React, { useEffect, useLayoutEffect, useState } from 'react'
import { getParameterByName } from 'src/shared/helpers'
import { alphaCode } from 'src/utils/alpha-code-utms'
import { WIDTH_MD } from 'src/utils/breakpoints'

// Hooks
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'

// Components
import ImageWebp from 'src/components/ImageWebp'
import { Modal } from 'src/components/Modal'
import ModalAccount from 'src/components/UI/Forms/OpenAccountRightForm'

import { Section } from './style'

const ToSaveMoney = () => {
  const width = useWidth()

  const [ sendDatalayerEvent ] = useDataLayer()
  const [ utmSource, setUtmSource ] = useState<string>('9io9bkj')
  const [ utmCampaign, setUtmCampaign ] = useState<string>('site_contadigital_aquisicao')
  const [ isMobile, setIsMobile ] = useState(false)
  const domReady = useDomReady()
  const [ open, setOpen ] = useState(false)

  const url = `https://bancointer.go.link?adj_t=${alphaCode(utmSource)}&adj_campaign=${utmCampaign}&adj_adgroup=contadigital&adj_creative=lp-cdpf-mobile&adj_label=aquisicao&adj_fallback=https%3A%2F%2Fwww.bancointer.com`

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Conta Digital. Gratuita, simples e completa',
    element_name: 'Abra sua conta',
    element_action: 'click button',
    redirect_url: url,
  })

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', '') as string || '9io9bkj')
    setUtmCampaign(getParameterByName('utm_campaign', '') as string || 'site_contadigital_aquisicao')
  }, [])

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  const openModal = domReady && (
    <Modal isModalOpen={open} setIsModalOpen={setOpen} locationToRender={document.body}>
      <ModalAccount
        closeModal={() => setOpen(false)} dataLayer={dataLayer}
      />
    </Modal>
  )

  return (
    <Section className='pt-5 d-flex align-items-end'>
      {openModal}
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 offset-md-2 offset-xl-4 order-md-last mb-5'>
            <h2 className='fs-24 fs-lg-40 fs-xl-48 lh-28 lh-lg-48 lh-xl-56 text-grayscale--500 fw-500 text-center text-md-left'>
              Cupons de desconto exclusivos: pegue o seu!
            </h2>
            <a
              href='https://intergo.app/2f9e64e2'
              target='_blank'
              className='btn btn--lg bg-primary--500 rounded-2 fs-14 fw-600 text-none text-white mt-3 mb-0 mw-100'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_03',
                  section_name: 'Cupons de desconto exclusivos: pegue o seu!',
                  element_action: 'click scroll',
                  element_name: 'Pegar cupom',
                  redirect_url: 'https://intergo.app/2f9e64e2',
                })
              }} rel='noreferrer'
            >
              Pegar cupom
            </a>
          </div>
          <div className='col-12 col-md-4 col-lg-3 offset-lg-1 offset-xl-0 d-flex justify-content-center'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/1440-dobra3-phone/image.webp'
              altDescription='homem jovem segurando o celular com as duas mãos e marca de lojas ao lado, como centauro, casas bahia, amazon e mais'
              arrayNumbers={[ 300, 280, 340, 370 ]}
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ToSaveMoney
